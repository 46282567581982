@tailwind base;
@tailwind components;
@tailwind utilities;

#telegram-login-EtoZnakDBot{
    text-align:center;
    margin: auto;
}

#telegram-login-EtoZnakGameBot{
    text-align:center;
    margin: auto;
}

#telegram-login-EtoZnakGameStagingbot{
    text-align:center;
    margin: auto;
}

/* fix for game list grid */
/*https://github.com/vercel/next.js/issues/18915 */
figure > span {
    display: block !important;
}
